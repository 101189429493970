import { msalApiFetch } from "../authConfig";
import { Warehouse } from "../context/WarehouseContext";

export interface A4CertificateRequest {
  requestID: number;
  certificateNumber: string;
  sku: number;
  store: string;
  dateRequested: Date;
  status: CertificateRequestStatus;
  certificateVendor: string;
}

export enum CertificateRequestStatus {
  OrderReceived = 1,
  PrintedAwaitingDespatch = 2,
  OnOrderFromSupplier = 3,
  Despatched = 4,
  OrderComplete = 5,
  Cancelled = 6,
}

export interface A4CertificateUpdate {
  requestID: number;
  status: CertificateRequestStatus;
}

export const A4CertificateRequestApi = {
  get: (
    warehouse: Warehouse,
    show: "open" | "closed"
  ): Promise<A4CertificateRequest[]> =>
    msalApiFetch(
      `/a4-certificate-requests?warehouse=${warehouse}&show=${show}`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      const content: A4CertificateRequest[] = await response.json();
      return content;
    }),
  update: (req: A4CertificateUpdate): Promise<void> =>
    msalApiFetch("/a4-certificate-requests", {
      method: "PUT",
      body: JSON.stringify(req),
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
    }),
};
