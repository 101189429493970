import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import { Order } from "../EnhancedTable/EnhancedTable";
import {
  Backdrop,
  Button,
  CircularProgress,
  InputBase,
} from "@material-ui/core";
import EnhancedTableHead from "./EnhancedTableHead";
import DiamondCertificateApi, {
  CertificateDetails,
} from "../../api/DiamondCertificateApi";
import FilterIcon from "@material-ui/icons/FilterList";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
      flex: "1 1 100%",
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      marginRight: "200px",
    },
    search: {
      position: "relative",
      display: "flex",
    },
    searchButton: {
      "&:hover": {
        backgroundColor: lighten(theme.palette.primary.main, 0.3),
      },
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: "10px",
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: lighten(theme.palette.secondary.light, 0.7),
      "&:hover": {
        backgroundColor: lighten(theme.palette.secondary.light, 0.5),
      },
      marginRight: theme.spacing(2),
    },
  })
);

export interface EnhancedToolBarProps {
  filter: (filterString: string) => void;
}

const EnhancedTableToolbar = ({ filter }: EnhancedToolBarProps) => {
  const classes = useToolbarStyles();
  const [filterString, setFilterString] = useState("");

  return (
    <Toolbar className={clsx(classes.root, {})}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Certificates
      </Typography>
      <form
        className={classes.search}
        onSubmit={(e) => {
          e.preventDefault();
          filter(filterString);
        }}
      >
        <InputBase
          placeholder="Certificate Number…"
          type={"number"}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={(e) => setFilterString(e.currentTarget.value)}
        />
        <Button
          className={classes.searchButton}
          variant="contained"
          color="primary"
          onClick={() => filter(filterString)}
        >
          <FilterIcon />
        </Button>
      </form>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    editableCell: {
      padding: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface Props {
  setTitle: (title: string) => void;
}

interface CertificatesState {
  order: Order;
  orderBy: keyof CertificateDetails;
  page: number;
  rowsPerPage: number;
  certificateNumberFilter: string;
}

export default function EnhancedTable(props: Props): JSX.Element {
  const [state, setState] = useState<CertificatesState>({
    order: "asc",
    orderBy: "created",
    page: 0,
    rowsPerPage: 10,
    certificateNumberFilter: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<CertificateDetails[]>([]);
  const [numberOfCertififcates, setNumberOfCertififcates] = useState(0);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.setTitle("Certificates");
  });

  useEffect(() => {
    setIsLoading(true);
    DiamondCertificateApi.getCertificateList(
      state.page,
      state.rowsPerPage,
      state.certificateNumberFilter
    )
      .then((res) => {
        setRows(res.certificates);
        setNumberOfCertififcates(res.totalCount);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  }, [
    state.certificateNumberFilter,
    enqueueSnackbar,
    state.page,
    state.rowsPerPage,
  ]);

  const classes = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof CertificateDetails
  ) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({
      ...state,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setState({
      ...state,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      ...state,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const shortenString = (length: number, str?: string) =>
    str && str.length > length ? str.slice(0, length - 3) + "..." : str;

  const emptyRows =
    state.rowsPerPage - Math.min(state.rowsPerPage, rows.length);

  const filterRows = (filterString: string) => {
    console.log("filterRows", filterString);
    setState({ ...state, page: 0, certificateNumberFilter: filterString });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        <EnhancedTableToolbar filter={filterRows} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="requests table"
          >
            <EnhancedTableHead
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {rows.map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell padding="checkbox"></TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      {row.certificateNumber}
                    </TableCell>
                    <TableCell align="left">{row.sku}</TableCell>
                    <TableCell align="left">{row.clarity}</TableCell>
                    <TableCell align="left">{row.colour}</TableCell>
                    <TableCell align="left" width={"250px"}>
                      {row.comments}
                    </TableCell>
                    <TableCell align="left" width={"120px"}>
                      {row.cutGrade}
                    </TableCell>
                    <TableCell align="left">{row.measurements}</TableCell>
                    <TableCell align="left" width={"130px"}>
                      {row.polish}
                    </TableCell>
                    <TableCell align="left" width={"130px"}>
                      {row.shape}
                    </TableCell>
                    <TableCell align="left">{row.treatment}</TableCell>
                    <TableCell align="left">{row.weight}</TableCell>
                    <TableCell align="left">
                      {new Date(row.created).toLocaleString("en-AU")}
                    </TableCell>
                    <TableCell align="left">
                      {shortenString(15, row.createdBy)}
                    </TableCell>
                    <TableCell align="left">
                      {row.modified
                        ? new Date(row.modified).toLocaleString("en-AU")
                        : ""}
                    </TableCell>
                    <TableCell align="left">
                      {shortenString(15, row.modifiedBy)}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={numberOfCertififcates}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
