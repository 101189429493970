export type AutoCompleteOption = { title: string; value: string };

export const None = { title: "", value: "" };

export const GradingCompanyOptions = ["ADGL", "GSI", "IGI"].map((value) => {
  return { title: value, value };
});

export const ColourGradeOptions = [
  "D",
  "D-E",
  "E",
  "E-F",
  "F",
  "F-G",
  "G",
  "G-H",
  "H",
  "H-I",
  "I",
  "I-J",
  "J",
  "J-K",
  "K",
  "K-L",
  "L",
  "L-M",
  "M",
  "M-N",
  "N",
  "N-O",
  "O",
  "O-P",
  "Other",
].map((value) => {
  return { title: value, value };
});

export const ClarityGradeOptions = [
  "F",
  "IF",
  "VVS1",
  "VVS2",
  "VS1",
  "VS2",
  "SI1",
  "SI2",
  "I1",
  "I2",
  "I3",
].map((value) => {
  return { title: value, value };
});
