import React from "react";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import { Toolbar, Typography, Tooltip, IconButton } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import TableData from "./TableData";
import PrintIcon from "@material-ui/icons/Print";
import clsx from "clsx";

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

export interface EnhancedTableToolbarProps {
  numSelected: number;
  printSelection: () => void;
  toggleOpenClosed: () => void;
  openClosedStatus: "open" | "closed";
  selectedCertificates: TableData[];
}

export const EnhancedTableToolbar = (
  props: EnhancedTableToolbarProps
): JSX.Element => {
  const classes = useToolbarStyles();
  const { numSelected, openClosedStatus, toggleOpenClosed } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Requests
        </Typography>
      )}
      <Tooltip title="Toggle open/closed requests">
        <ToggleButtonGroup
          value={openClosedStatus}
          exclusive
          onChange={toggleOpenClosed}
          aria-label="text alignment"
        >
          <ToggleButton value="open" aria-label="open">
            <span>Open</span>
          </ToggleButton>
          <ToggleButton value="closed" aria-label="closed">
            <span>Closed</span>
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>

      <Tooltip title="Print">
        <span>
          <IconButton
            aria-label="print"
            onClick={props.printSelection}
            disabled={numSelected === 0}
          >
            <PrintIcon />
          </IconButton>
        </span>
      </Tooltip>
    </Toolbar>
  );
};
