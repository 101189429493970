export const environment = {
  authConfig: {
    apiClientId: "#{auth.ApiClientId}",
    spaClientId: "#{auth.SpaClientId}",
    tenantId: "#{auth.TenantId}",
    timeout: Number("#{auth.Timeout}"),
  },
  apiConfig: {
    baseUrl: "#{api.baseUrl}",
  },
  build: {
    version: "#{build.Version}",
  },
};
