import { TableHead, TableRow, TableCell } from "@material-ui/core";
import { CertificateDetails } from "../../api/DiamondCertificateApi";
import { Order } from "../EnhancedTable/EnhancedTable";

interface HeadCell {
  disablePadding: boolean;
  id: keyof CertificateDetails;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "certificateNumber",
    numeric: false,
    disablePadding: true,
    label: "Certificate #",
  },
  { id: "sku", numeric: true, disablePadding: false, label: "Sku" },
  { id: "clarity", numeric: false, disablePadding: false, label: "Clarity" },
  { id: "colour", numeric: false, disablePadding: false, label: "Colour" },
  { id: "comments", numeric: false, disablePadding: false, label: "Comments" },
  { id: "cutGrade", numeric: false, disablePadding: false, label: "Cut Grade" },
  {
    id: "measurements",
    numeric: false,
    disablePadding: false,
    label: "Measurements",
  },
  { id: "polish", numeric: false, disablePadding: false, label: "Polish" },
  { id: "shape", numeric: false, disablePadding: false, label: "Shape" },
  {
    id: "treatment",
    numeric: false,
    disablePadding: false,
    label: "Treatment",
  },
  { id: "weight", numeric: true, disablePadding: false, label: "Weight" },
  {
    id: "created",
    numeric: false,
    disablePadding: false,
    label: "Created",
  },
  {
    id: "createdBy",
    numeric: false,
    disablePadding: false,
    label: "Created By",
  },
  {
    id: "modified",
    numeric: false,
    disablePadding: false,
    label: "Modified",
  },
  {
    id: "modifiedBy",
    numeric: false,
    disablePadding: false,
    label: "Modified By",
  },
];

export interface EnhancedTableHeadProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof CertificateDetails
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const EnhancedTableHead = (props: EnhancedTableHeadProps): JSX.Element => {
  const { order, orderBy } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
