import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Api from "../../api/DiamondCertificateApi";
import { environment } from "../../environments/environment";
import CheckCircle from "@material-ui/icons/CheckCircle";
import { Help } from "@material-ui/icons";
import { useSnackbar } from "notistack";

const pdfBaseUrl = environment.apiConfig.baseUrl + "/pdf?certificate=";
type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

interface Props {
  setTitle: (title: string) => void;
}

interface QuickPrintState {
  certificateNumber: string;
  validCertificateNumber: boolean | null;
  sku: string;
  formIsValid: boolean;
  isLoading: boolean;
  igiCheckDone: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

function QuickPrint(props: Props): JSX.Element {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<QuickPrintState>({
    certificateNumber: "",
    validCertificateNumber: null,
    sku: "",
    formIsValid: false,
    isLoading: false,
    igiCheckDone: false,
  });

  const skuInputRef = useRef<HTMLInputElement>(null);
  const printButtonRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    props.setTitle("Quick Print");
  });

  const modifyCertificateNumber = (e: ChangeEvent) => {
    setState({
      ...state,
      certificateNumber: e.target.value,
      validCertificateNumber: null,
      formIsValid: false,
      sku: "",
      igiCheckDone: false,
    });
  };

  const modifySku = (e: ChangeEvent) => {
    setState({
      ...state,
      formIsValid: false,
      sku: e.target.value,
    });
  };

  const saveSku = () => {
    setState({ ...state, isLoading: true });
    return Api.updateSku(state.certificateNumber, parseInt(state.sku))
      .then(() => {
        setState({
          ...state,
          formIsValid: true,
          isLoading: false,
        });

        if (printButtonRef.current) {
          printButtonRef.current.focus();
        }
      })
      .catch((e) => {
        setState({ ...state, isLoading: false });
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const loadSku = () => {
    setState({ ...state, isLoading: true });
    return Api.getCertificate(state.certificateNumber)
      .then((cert) => {
        setState({
          ...state,
          validCertificateNumber: true,
          sku: cert.sku ? cert.sku.toString() : "",
          formIsValid: !!cert.sku,
          isLoading: false,
        });
        if (skuInputRef.current) {
          skuInputRef.current.focus();
        }
      })
      .catch((e) => {
        setState({
          ...state,
          formIsValid: false,
          validCertificateNumber: false,
          isLoading: false,
        });
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const loadCertFromIGI = () => {
    setState({ ...state, isLoading: true });
    return Api.getCertificateFromIgi(state.certificateNumber)
      .then(() => setState({ ...state, igiCheckDone: true }))
      .then(loadSku)
      .catch((e) => {
        setState({
          ...state,
          formIsValid: false,
          validCertificateNumber: false,
          isLoading: false,
          igiCheckDone: true,
        });
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (state.formIsValid) {
      window.open(pdfBaseUrl + state.certificateNumber, "_blank");
    } else if (!!state.sku && !!state.certificateNumber) {
      saveSku();
    } else if (
      !!state.certificateNumber &&
      state.validCertificateNumber === null
    ) {
      loadSku();
    } else if (
      !!state.certificateNumber &&
      !state.validCertificateNumber &&
      !state.igiCheckDone
    ) {
      loadCertFromIGI();
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      onSubmit();
    }
  };

  const printPdf = () => {
    setState({ ...state, isLoading: true });
    Api.bulkPrintCertificates([state.certificateNumber])
      .then((blob) => {
        setState({ ...state, isLoading: false });
        const dataUrl = window.URL.createObjectURL(blob);
        const pdfWindow = window.open(dataUrl);
        pdfWindow?.print();
      })
      .catch((e) => {
        setState({ ...state, isLoading: false });
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  return (
    <form noValidate autoComplete="off" onKeyDown={onKeyDown}>
      <Backdrop className={classes.backdrop} open={state.isLoading}>
        <CircularProgress />
      </Backdrop>
      <Grid container direction={"column"} spacing={2}>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justify={"flex-start"}
          >
            <Grid item xs={10}>
              <TextField
                id="filled-basic"
                label="Diamond Certificate Number"
                fullWidth={true}
                variant="filled"
                onChange={modifyCertificateNumber}
                onBlur={loadSku}
                error={state.validCertificateNumber === false}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid
            container
            direction={"row"}
            spacing={2}
            alignItems={"center"}
            justify={"flex-start"}
          >
            <Grid item xs={10}>
              <TextField
                id="filled-basic"
                label="SKU"
                variant="filled"
                type="number"
                fullWidth={true}
                value={state.sku}
                disabled={!state.validCertificateNumber}
                error={!!state.validCertificateNumber && !state.formIsValid}
                inputRef={skuInputRef}
                onChange={modifySku}
              />
            </Grid>
            {state.formIsValid ? (
              <CheckCircle color={"secondary"}></CheckCircle>
            ) : state.validCertificateNumber && !state.formIsValid ? (
              <Help color={"error"}></Help>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {state.formIsValid ? (
            <Button
              variant="contained"
              color="primary"
              style={{ minWidth: "100px" }}
              onClick={printPdf}
              disabled={state.isLoading}
            >
              Print
            </Button>
          ) : state.validCertificateNumber &&
            !!state.sku &&
            !state.formIsValid ? (
            <Button
              variant="contained"
              style={{ minWidth: "100px" }}
              color="primary"
              disabled={!state.sku || state.isLoading}
              onClick={saveSku}
            >
              Save Sku
            </Button>
          ) : state.validCertificateNumber === false && !state.igiCheckDone ? (
            <Button
              variant="contained"
              style={{ minWidth: "100px" }}
              color="primary"
              disabled={!state.certificateNumber || state.isLoading}
              onClick={loadCertFromIGI}
              innerRef={printButtonRef}
            >
              Load from IGI
            </Button>
          ) : (
            <Button
              variant="contained"
              style={{ minWidth: "100px" }}
              color="primary"
              disabled={
                !state.certificateNumber ||
                state.isLoading ||
                (state.igiCheckDone && !state.validCertificateNumber)
              }
              onClick={loadSku}
              innerRef={printButtonRef}
            >
              Load Sku
            </Button>
          )}
        </Grid>
      </Grid>
    </form>
  );
}

export default QuickPrint;
