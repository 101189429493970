import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Fab,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Api, { CertificateSkuPair } from "../../api/DiamondCertificateApi";
import Delete from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import { Add } from "@material-ui/icons";

interface Props {
  setTitle: (title: string) => void;
}

interface Certificate {
  certificateNumber: string;
  sku: string;
  isValid: boolean;
}

interface BulkPrintState {
  isLoading: boolean;
  certificates: Certificate[];
  invalidCertificateNumber: boolean;
  igiCheckDone: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    control: {
      padding: `${theme.spacing(2)}px 0`,
    },

    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  })
);

function BulkPrint(props: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();

  const [state, setState] = useState<BulkPrintState>({
    certificates: [],
    invalidCertificateNumber: false,
    isLoading: false,
    igiCheckDone: false,
  });

  const newCertificateRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    props.setTitle("Bulk Print");
  });

  const addNewCertificate = (value: string) => {
    if (value) {
      setState({ ...state, isLoading: true });
      Api.getCertificate(value)
        .then((cert) => {
          const newCert = {
            certificateNumber: cert.certificateNumber,
            validCertificateNumber: true,
            sku: cert.sku ? cert.sku.toString() : "",
            isValid: !!cert.sku,
          };
          setState({
            ...state,
            isLoading: false,
            invalidCertificateNumber: false,
            certificates: [...state.certificates, newCert],
          });
          if (newCertificateRef.current) {
            newCertificateRef.current.value = "";
          }
        })
        .catch((e) => {
          setState({
            ...state,
            isLoading: false,
            invalidCertificateNumber: true,
          });
          enqueueSnackbar(e.toString(), { variant: "error" });
        });
    }
  };

  const deleteCertificate = (index: number) => {
    const certs = [...state.certificates];
    certs.splice(index, 1);
    setState({ ...state, certificates: certs });
  };

  const modifySku = (value: string, index: number) => {
    const certs = [...state.certificates];
    const cert = certs[index];

    certs[index] = {
      isValid: true,
      certificateNumber: cert.certificateNumber,
      sku: value,
    };

    setState({ ...state, certificates: certs });
  };

  const applyValidation = (invalidSkus: CertificateSkuPair[]) => {
    const certs = [...state.certificates];

    certs.forEach((cert) => {
      cert.isValid =
        !!cert.sku &&
        invalidSkus.every(
          (s) => cert.certificateNumber !== s.certificateNumber
        );
    });

    setState({ ...state, certificates: certs });
  };

  const saveSkus = () => {
    setState({ ...state, isLoading: true });
    return Api.bulkUpdateSkus(
      state.certificates
        .filter((c) => !!c.sku)
        .map((c) => ({
          certificateNumber: c.certificateNumber,
          sku: parseInt(c.sku),
        }))
    ).then((invalidSkus) => {
      setState({ ...state, isLoading: false });
      applyValidation(invalidSkus);
      if (invalidSkus.length) {
        return Promise.reject("All certificates require a valid SKU");
      }
    });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLFormElement>): void => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (newCertificateRef.current?.value) {
        addNewCertificate(newCertificateRef.current?.value);
      }
    }
  };

  const printPdf = () => {
    setState({ ...state, isLoading: true });
    return saveSkus()
      .then((e) => {
        return Api.bulkPrintCertificates(
          state.certificates.map((c) => c.certificateNumber)
        );
      })
      .then((blob) => {
        const dataUrl = window.URL.createObjectURL(blob);
        const pdfWindow = window.open(dataUrl);
        pdfWindow?.print();
      })
      .catch((e) => {
        enqueueSnackbar(e.toString(), { variant: "error" });
      })
      .finally(() => setState({ ...state, isLoading: false }));
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const reader = new FileReader();

    if (files?.length) {
      reader.onload = (() => (e: ProgressEvent<FileReader>) => {
        handleCsvContent(e.target?.result?.toString());
      })();

      reader.readAsText(files[0]);
    }
  };

  const handleCsvContent = (data: string | undefined) => {
    if (!data) return;

    const certRegEx = /^[a-z0-9]{1,20}$/i;
    const skuRegEx = /^\d+$/;
    const newLineRegEx = /\r?\n|\n/g;
    let certData: Certificate[] = [];
    try {
      certData = data
        .split(newLineRegEx)
        .reduce<Certificate[]>((result, row, idx) => {
          const cells = row.split(",");
          switch (cells.length) {
            case 0:
              break;
            case 1: {
              const certificateNumber = cells[0].trim();
              if (!!certificateNumber) {
                if (!certRegEx.test(certificateNumber)) {
                  throw new Error(
                    `Invalid certificate number '${certificateNumber}' in row ${idx}`
                  );
                }
                result.push({ certificateNumber, sku: "", isValid: false });
              }
              break;
            }
            case 2: {
              const certificateNumber = cells[0].trim();
              if (!certRegEx.test(certificateNumber)) {
                throw new Error(
                  `Invalid certificate number '${
                    certificateNumber || "empty"
                  }' in row ${idx}`
                );
              }
              const sku = cells[1].trim();
              if (sku && !skuRegEx.test(sku)) {
                throw new Error(`Invalid SKU '${sku}' in row ${idx}`);
              }
              result.push({ certificateNumber, sku, isValid: false });
              break;
            }
            default: {
              throw new Error(`Invalid data in row ${idx}: '${row}'`);
            }
          }
          return result;
        }, []);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (ex: any) {
      enqueueSnackbar(ex.toString(), { variant: "error" });
      return;
    }

    if (!certData || certData.length === 0) {
      enqueueSnackbar("Invalid or Empty Data", { variant: "error" });
      return;
    }

    Api.getCertificates(certData.map((c) => c.certificateNumber))
      .then((response) => {
        const errors: string[] = [];
        const newCerts = certData.reduce<Certificate[]>((acc, cur) => {
          const match = response.find(
            (r) => r.certificateNumber === cur.certificateNumber
          );
          if (match) {
            acc.push({
              certificateNumber: cur.certificateNumber,
              sku: cur.sku,
              isValid: !!cur.sku,
            });
          } else {
            errors.push(cur.certificateNumber);
          }
          return acc;
        }, []);
        setState({
          ...state,
          certificates: [...state.certificates, ...newCerts],
        });
        if (errors.length > 0) {
          enqueueSnackbar(
            `Invalid certificate numbers: '${errors.join(", ")}'`,
            { variant: "error" }
          );
        }
      })
      .catch((e) => {
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const loadCertFromIGI = () => {
    if (newCertificateRef.current?.value) {
      setState({ ...state, isLoading: true });
      return Api.getCertificateFromIgi(newCertificateRef.current?.value)
        .then(() => setState({ ...state, igiCheckDone: true }))
        .then(() => {
          if (newCertificateRef.current?.value) {
            addNewCertificate(newCertificateRef.current?.value);
          } else {
            setState({ ...state, isLoading: false });
          }
        })
        .catch((e) => {
          setState({
            ...state,
            igiCheckDone: true,
            invalidCertificateNumber: true,
            isLoading: false,
          });
          enqueueSnackbar(e.toString(), { variant: "error" });
        });
    }
  };

  return (
    <>
      <form noValidate autoComplete="off" onKeyDown={onKeyDown}>
        <Backdrop className={classes.backdrop} open={state.isLoading}>
          <CircularProgress />
        </Backdrop>
        <Grid container direction={"column"} spacing={2}>
          <Grid item xs={12} sm={10}>
            <Typography variant="h6" gutterBottom>
              Manual Entry
            </Typography>
            {state.certificates.map((cert, index) => (
              <Grid
                key={index}
                container
                direction={"row"}
                spacing={2}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid item xs={10} lg={5}>
                  <TextField
                    id="filled-basic"
                    label="Diamond Certificate Number"
                    fullWidth={true}
                    variant="filled"
                    value={cert.certificateNumber}
                    helperText={" "}
                    contentEditable={false}
                  />
                </Grid>

                <Grid item xs={10} lg={5}>
                  <TextField
                    id="filled-basic"
                    label="SKU"
                    variant="filled"
                    type="number"
                    fullWidth={true}
                    value={cert.sku}
                    error={!cert.isValid}
                    helperText={(!cert.isValid && "invalid SKU") || " "}
                    onChange={(e) => modifySku(e.target.value, index)}
                    autoFocus={
                      !cert.isValid && index === state.certificates.length - 1
                    }
                  />
                </Grid>
                <Delete onClick={() => deleteCertificate(index)} />
              </Grid>
            ))}
            <Grid
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid item xs={9} lg={5}>
                <TextField
                  id="filled-basic"
                  label="Diamond Certificate Number"
                  fullWidth={true}
                  variant="filled"
                  inputRef={newCertificateRef}
                  onChange={() => setState({ ...state, igiCheckDone: false })}
                  error={state.invalidCertificateNumber}
                />
              </Grid>
              <Grid item xs={3} lg={3}>
                <label htmlFor="upload-csv">
                  <input
                    style={{ display: "none" }}
                    id="upload-csv"
                    name="upload-csv"
                    type="file"
                    accept=".csv"
                    onChange={handleFileSelect}
                  />

                  <Fab
                    color="primary"
                    size="large"
                    component="span"
                    aria-label="add"
                    variant="extended"
                  >
                    <Add /> Upload CSV
                  </Fab>
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.control}>
              <Grid
                container
                direction={"row"}
                spacing={1}
                alignItems={"center"}
                justify={"flex-start"}
              >
                <Grid item xs={2} lg={1}>
                  <Button
                    variant="contained"
                    style={{ minWidth: "100px" }}
                    color="primary"
                    onClick={() => printPdf()}
                    disabled={
                      !state.certificates.length ||
                      !state.certificates.every((c) => !!c.sku) ||
                      state.isLoading
                    }
                  >
                    Print
                  </Button>
                </Grid>
                {!state.igiCheckDone && state.invalidCertificateNumber ? (
                  <Grid item xs={3} lg={2}>
                    <Button
                      variant="contained"
                      style={{ minWidth: "100px" }}
                      color="primary"
                      onClick={loadCertFromIGI}
                      disabled={state.isLoading}
                    >
                      Load from IGI
                    </Button>
                  </Grid>
                ) : (
                  <> </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </>
  );
}

export default BulkPrint;
