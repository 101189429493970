import { msalApiFetch } from "../authConfig";
import { Warehouse } from "../context/WarehouseContext";

export interface CardCertificateRequest {
  requestID: number;
  certificateNumber: string;
  storeSku: number;
  systemSku: number | undefined;
  store: string;
  dateRequested: Date;
  status: string;
  certificateVendor: string;
}

export const CardCertificateRequestApi = {
  get: (
    warehouse: Warehouse,
    show: "open" | "closed"
  ): Promise<CardCertificateRequest[]> =>
    msalApiFetch(
      `/card-certificate-requests?warehouse=${warehouse}&show=${show}`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      const content: CardCertificateRequest[] = await response.json();
      return content;
    }),
  printCertificates: (certificateNumbers: string[]): Promise<Blob> =>
    msalApiFetch(`/card-certificate-requests/print`, {
      method: "POST",
      body: JSON.stringify(certificateNumbers),
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      return await response.blob();
    }),
  cancel: (requestId: number): Promise<void> =>
    msalApiFetch(`/card-certificate-requests/${requestId}/cancel`, {
      method: "PUT",
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
    }),
};
