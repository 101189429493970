import React, { useEffect, useState } from "react";
import clsx from "clsx";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from "@material-ui/icons/Save";
import {
  A4CertificateRequestApi,
  CertificateRequestStatus,
} from "../../api/A4CertificateRequestApi";
import { useWarehouse } from "../../context/WarehouseContext";
import { useSnackbar } from "notistack";
import {
  getComparator,
  stableSort,
  Order,
} from "../EnhancedTable/EnhancedTable";
import {
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

interface Data {
  requestID: number;
  certificateNumber: string;
  store: string;
  createdDate: Date;
  status: string;
  prevStatus: string;
  certificateVendor: string;
}

function createData(
  requestID: number,
  certificateNumber: string,
  store: string,
  createdDate: Date,
  status: CertificateRequestStatus,
  certificateVendor: string
): Data {
  return {
    requestID,
    certificateNumber,
    store,
    createdDate,
    status: status.toString(),
    prevStatus: status.toString(),
    certificateVendor,
  };
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "certificateNumber",
    numeric: false,
    disablePadding: true,
    label: "Certificate Number",
  },
  { id: "store", numeric: false, disablePadding: false, label: "Store" },
  {
    id: "certificateVendor",
    numeric: false,
    disablePadding: false,
    label: "Grading Company",
  },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
  })
);

interface EnhancedTableToolbarProps {
  toggleOpenClosed: () => void;
  openClosedStatus: "open" | "closed";
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { openClosedStatus, toggleOpenClosed } = props;

  return (
    <Toolbar className={clsx(classes.root, {})}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Requests
      </Typography>
      <Tooltip title="Toggle open/closed requests">
        <ToggleButtonGroup
          value={openClosedStatus}
          exclusive
          onChange={toggleOpenClosed}
          aria-label="text alignment"
        >
          <ToggleButton value="open" aria-label="open">
            Open
          </ToggleButton>
          <ToggleButton value="closed" aria-label="closed">
            Closed
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Toolbar>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(0),
      minWidth: 120,
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    editableCell: {
      padding: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

interface Props {
  setTitle: (title: string) => void;
}

interface A4CertificateRequestsState {
  order: Order;
  orderBy: keyof Data;
  page: number;
  rowsPerPage: number;
  showOpenOrClosed: "open" | "closed";
}

export default function EnhancedTable(props: Props): JSX.Element {
  const [state, setState] = useState<A4CertificateRequestsState>({
    order: "asc",
    orderBy: "createdDate",
    page: 0,
    rowsPerPage: 10,
    showOpenOrClosed: "open",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<Data[]>([]);
  const { warehouse } = useWarehouse();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.setTitle("A4 Certificate Requests");
  });

  useEffect(() => {
    setIsLoading(true);
    A4CertificateRequestApi.get(warehouse, state.showOpenOrClosed)
      .then((res) => {
        setRows(
          res.map((r) =>
            createData(
              r.requestID,
              r.certificateNumber,
              r.store,
              r.dateRequested,
              r.status,
              r.certificateVendor
            )
          )
        );
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  }, [warehouse, state.showOpenOrClosed, enqueueSnackbar]);

  const classes = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({
      ...state,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const save = (row: Data) => {
    const upd = {
      requestID: row.requestID,
      status:
        CertificateRequestStatus[
          row.status as keyof typeof CertificateRequestStatus
        ],
    };

    setIsLoading(true);

    A4CertificateRequestApi.update(upd)
      .then(() =>
        A4CertificateRequestApi.get(warehouse, state.showOpenOrClosed)
      )
      .then((res) => {
        setRows(
          res.map((r) =>
            createData(
              r.requestID,
              r.certificateNumber,
              r.store,
              r.dateRequested,
              r.status,
              r.certificateVendor
            )
          )
        );
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const toggleOpenClosed = () => {
    setState({
      ...state,
      showOpenOrClosed: state.showOpenOrClosed === "open" ? "closed" : "open",
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setState({
      ...state,
      page: newPage,
    });
  };

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    row: Data
  ) => {
    const index = rows.findIndex((r) => r.requestID === row.requestID);

    const update = rows.map((item, idx) => {
      if (idx === index) {
        return {
          ...item,
          status: event.target.value as string,
        };
      } else {
        return item;
      }
    });

    setRows(update);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      ...state,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const emptyRows =
    state.rowsPerPage -
    Math.min(state.rowsPerPage, rows.length - state.page * state.rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Backdrop className={classes.backdrop} open={isLoading}>
          <CircularProgress />
        </Backdrop>
        <EnhancedTableToolbar
          openClosedStatus={state.showOpenOrClosed}
          toggleOpenClosed={toggleOpenClosed}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="requests table"
          >
            <EnhancedTableHead
              classes={classes}
              order={state.order}
              orderBy={state.orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(state.order, state.orderBy))
                .slice(
                  state.page * state.rowsPerPage,
                  state.page * state.rowsPerPage + state.rowsPerPage
                )
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isChanged = row.status !== row.prevStatus;

                  return (
                    <TableRow hover tabIndex={-1} key={row.requestID}>
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.certificateNumber}
                      </TableCell>
                      <TableCell align="left">{row.store}</TableCell>
                      <TableCell align="left">
                        {row.certificateVendor}
                      </TableCell>
                      <TableCell align="left">
                        {new Date(row.createdDate).toLocaleString("en-AU")}
                      </TableCell>
                      <TableCell align="right" className={classes.editableCell}>
                        <FormControl
                          variant="filled"
                          className={classes.formControl}
                        >
                          <InputLabel id="demo-simple-select-filled-label">
                            Status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            value={row.status}
                            disabled={
                              row.status !== "OnOrderFromSupplier" &&
                              row.status !== "OrderReceived"
                            }
                            onChange={(event) => handleChange(event, row)}
                          >
                            <MenuItem
                              value={
                                CertificateRequestStatus[
                                  CertificateRequestStatus.OrderReceived
                                ]
                              }
                            >
                              Received
                            </MenuItem>
                            <MenuItem
                              value={
                                CertificateRequestStatus[
                                  CertificateRequestStatus.OnOrderFromSupplier
                                ]
                              }
                            >
                              Ordered
                            </MenuItem>
                            <MenuItem
                              value={
                                CertificateRequestStatus[
                                  CertificateRequestStatus.OrderComplete
                                ]
                              }
                            >
                              Complete
                            </MenuItem>
                            {state.showOpenOrClosed === "closed"
                              ? [
                                  <MenuItem
                                    key={CertificateRequestStatus.Despatched}
                                    value={
                                      CertificateRequestStatus[
                                        CertificateRequestStatus.Despatched
                                      ]
                                    }
                                  >
                                    Despatched
                                  </MenuItem>,
                                  <MenuItem
                                    key={
                                      CertificateRequestStatus.PrintedAwaitingDespatch
                                    }
                                    value={
                                      CertificateRequestStatus[
                                        CertificateRequestStatus
                                          .PrintedAwaitingDespatch
                                      ]
                                    }
                                  >
                                    Printed
                                  </MenuItem>,
                                  <MenuItem
                                    key={CertificateRequestStatus.Cancelled}
                                    value={
                                      CertificateRequestStatus[
                                        CertificateRequestStatus.Cancelled
                                      ]
                                    }
                                  >
                                    Cancelled
                                  </MenuItem>,
                                ]
                              : null}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell align="right" className={classes.editableCell}>
                        <Tooltip title="Save">
                          <span>
                            <IconButton
                              aria-label="save"
                              name="save"
                              onClick={() => save(row)}
                              disabled={!isChanged}
                            >
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
