import { environment } from "./environments/environment";
import {
  AccountInfo,
  AuthenticationResult,
  Configuration,
  EventType,
  EventMessage,
  PublicClientApplication,
  RedirectRequest,
  SilentRequest,
} from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    authority: `https://login.microsoftonline.com/${environment.authConfig.tenantId}/`,
    clientId: environment.authConfig.spaClientId,
    postLogoutRedirectUri: "/",
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};
export const pca = new PublicClientApplication(msalConfig);

const baseUrl = environment.apiConfig.baseUrl;
const accounts = pca.getAllAccounts();
if (accounts.length > 0) {
  pca.setActiveAccount(accounts[0]);
}

pca.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

export const loginRequest: RedirectRequest = {
  scopes: [`api://${environment.authConfig.apiClientId}/.default`],
};

export const authService = {
  GetToken(): Promise<AuthenticationResult> {
    const tokenRequest: SilentRequest = {
      account: pca.getActiveAccount() as AccountInfo,
      scopes: [`api://${environment.authConfig.apiClientId}/.default`],
    };
    return pca.acquireTokenSilent(tokenRequest);
  },
};

export const msalApiFetch = async (
  relativeUrl: string,
  options: RequestInit = {}
): Promise<Response> => {
  if (options && options.method !== "post")
    options.headers = {
      ...options.headers,
      ...{ pragma: "no-cache", "cache-control": "no-cache" },
    };
  if (options && options.method !== "get")
    options.headers = {
      ...options.headers,
      "Content-Type": "application/json",
    };
  const url = `${baseUrl}${relativeUrl}`;
  return await authService
    .GetToken()
    .then((r: AuthenticationResult) => {
      return {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${r.accessToken}`,
        },
      };
    })
    .then((opt) => fetch(url, opt))
    .then(
      (res: Response) => {
        const response = res.clone();
        if (!response.ok) {
          return response
            .json()
            .then(
              (problem) =>
                Promise.reject(problem.message || JSON.stringify(problem)), // api error
              () => Promise.reject(`${response.status}${response.statusText}`)
            ) // server error
            .catch((reason) => {
              throw new Error(reason);
            });
        }
        return res;
      },
      (reason) => {
        throw new Error(reason.message);
      }
    );
};
