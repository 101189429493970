import "./App.css";
import clsx from "clsx";
import { Switch, Route, withRouter } from "react-router-dom";
import QuickPrint from "./components/QuickPrint/QuickPrint";
import {
  AppBar,
  Button,
  createMuiTheme,
  createStyles,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  ThemeProvider,
  Toolbar,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import PrintIcon from "@material-ui/icons/Print";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CertificateIcon from "@material-ui/icons/Payment";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import VerifiedUserOutlinedIcon from "@material-ui/icons/VerifiedUserOutlined";
import { SnackbarProvider } from "notistack";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";
import A4CertificateRequests from "./components/A4CertificateRequests/A4CertificateRequests";
import CardCertificateRequests from "./components/CardCertificateRequests/CardCertificateRequests";
import ListItemLink from "./components/ListItemLink/ListItemLink";
import { Warehouse, WarehouseContext } from "./context/WarehouseContext";
import BulkPrint from "./components/BulkPrint/BulkPrint";
import AddCardCertificate from "./components/AddCardCertificate/AddCardCertificate";
import CardCertificates from "./components/CardCertificates/CardCertificates";

const drawerWidth = 256;

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#41173F",
      main: "#41173F",
      dark: "#41173F",
    },
    secondary: {
      light: "#BCA1CC",
      main: "#BCA1CC",
      dark: "#BCA1CC",
    },
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
    title: {
      flexGrow: 1,
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  })
);

function App() {
  const classes = useStyles();

  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState("");
  const [warehouse, setWarehouse] = React.useState(Warehouse.AU);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (warehouse: Warehouse) => {
    setWarehouse(warehouse);
    handleClose();
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ThemeProvider theme={theme}>
      <MsalAuthenticationTemplate
        authenticationRequest={loginRequest}
        interactionType={InteractionType.Redirect}
      >
        <SnackbarProvider maxSnack={3} autoHideDuration={15000}>
          <WarehouseContext.Provider value={{ warehouse, setWarehouse }}>
            <div className={classes.root}>
              <CssBaseline />
              <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                  [classes.appBarShift]: open,
                })}
              >
                <Toolbar>
                  <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={handleDrawerOpen}
                    edge="start"
                    className={clsx(classes.menuButton, {
                      [classes.hide]: open,
                    })}
                  >
                    <MenuIcon />
                  </IconButton>
                  <Typography variant="h6" className={classes.title}>
                    {title}
                  </Typography>
                  <div>
                    <Button
                      aria-label="warehouse"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleMenu}
                      color="inherit"
                      variant="outlined"
                    >
                      <Typography>{warehouse}</Typography>
                    </Button>
                    <Menu
                      id="menu-appbar"
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={menuOpen}
                      onClose={handleClose}
                    >
                      <MenuItem onClick={() => handleClick(Warehouse.AU)}>
                        AU
                      </MenuItem>
                      <MenuItem onClick={() => handleClick(Warehouse.CA)}>
                        CA
                      </MenuItem>
                      <MenuItem onClick={() => handleClick(Warehouse.None)}>
                        None
                      </MenuItem>
                    </Menu>
                  </div>
                </Toolbar>
              </AppBar>
              <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                  [classes.drawerOpen]: open,
                  [classes.drawerClose]: !open,
                })}
                classes={{
                  paper: clsx({
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                  }),
                }}
              >
                <div className={classes.toolbar}>
                  <IconButton onClick={handleDrawerClose}>
                    {theme.direction === "rtl" ? (
                      <ChevronRightIcon />
                    ) : (
                      <ChevronLeftIcon />
                    )}
                  </IconButton>
                </div>
                <Divider />
                <List>
                  <ListItemLink
                    to="/"
                    primary="Quick Print"
                    icon={<FlashOnIcon />}
                  />
                  <ListItemLink
                    to="/bulk-printing"
                    primary="Bulk Print"
                    icon={<PrintIcon />}
                  />
                  <ListItemLink
                    to="/card-certificate-add"
                    primary="Add Card Certificate"
                    icon={<PostAddIcon />}
                  />
                </List>
                <Divider />
                <List>
                  <ListItemLink
                    to="/card-certificate-requests"
                    primary="Card Requests"
                    icon={<CardMembershipIcon />}
                  />
                  <ListItemLink
                    to="/a4-certificate-requests"
                    primary="A4 Requests"
                    icon={<VerifiedUserOutlinedIcon />}
                  />
                  <ListItemLink
                    to="/certificates"
                    primary="Certificates"
                    icon={<CertificateIcon />}
                  />
                </List>
              </Drawer>
              <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                  <Route
                    exact
                    path="/"
                    key="Quick Print"
                    render={(props) => (
                      <QuickPrint {...props} setTitle={setTitle} />
                    )}
                  />
                  <Route
                    exact
                    path="/bulk-printing"
                    key="Bulk Printing"
                    render={(props) => (
                      <BulkPrint {...props} setTitle={setTitle} />
                    )}
                  />
                  <Route
                    exact
                    path="/card-certificate-add"
                    key="Add Card Certificate"
                    render={(props) => (
                      <AddCardCertificate {...props} setTitle={setTitle} />
                    )}
                  />
                  <Route
                    exact
                    path="/card-certificate-requests"
                    key="Card Certificate Requests"
                    render={(props) => (
                      <CardCertificateRequests {...props} setTitle={setTitle} />
                    )}
                  />
                  <Route
                    exact
                    path="/a4-certificate-requests"
                    key="A4 Certificate Requests"
                    render={(props) => (
                      <A4CertificateRequests {...props} setTitle={setTitle} />
                    )}
                  />
                  <Route
                    exact
                    path="/certificates"
                    key="Certificates"
                    render={(props) => (
                      <CardCertificates {...props} setTitle={setTitle} />
                    )}
                  />
                </Switch>
              </main>
            </div>
          </WarehouseContext.Provider>
        </SnackbarProvider>
      </MsalAuthenticationTemplate>
    </ThemeProvider>
  );
}

export default withRouter(App);
