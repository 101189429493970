import React from "react";
import TableHead from "@material-ui/core/TableHead";
import { Order } from "../EnhancedTable/EnhancedTable";
import {
  Checkbox,
  createStyles,
  makeStyles,
  TableCell,
  TableRow,
  TableSortLabel,
  Theme,
} from "@material-ui/core";
import TableData from "./TableData";

interface HeadCell {
  disablePadding: boolean;
  id: keyof TableData;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "certificateNumber",
    numeric: false,
    disablePadding: true,
    label: "Certificate Number",
  },
  {
    id: "systemSku",
    numeric: false,
    disablePadding: false,
    label: "System SKU",
  },
  { id: "storeSku", numeric: true, disablePadding: false, label: "Store SKU" },
  { id: "store", numeric: false, disablePadding: false, label: "Store" },
  {
    id: "certificateVendor",
    numeric: false,
    disablePadding: false,
    label: "Grading Company",
  },
  { id: "status", numeric: false, disablePadding: false, label: "Status" },
  {
    id: "createdDate",
    numeric: false,
    disablePadding: false,
    label: "Created Date",
  },
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof TableData
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  disableSelectAll: boolean;
}

export default function EnhancedTableHead(
  props: EnhancedTableProps
): JSX.Element {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    disableSelectAll,
  } = props;
  const createSortHandler =
    (property: keyof TableData) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all certificates" }}
            disabled={disableSelectAll}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
