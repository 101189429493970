import { msalApiFetch } from "../authConfig";

export interface Certificate {
  certificateNumber: string;
  sku: number | null;
  store: string;
}

export interface CreateCertificate {
  gradingCompany: string;
  certificateNumber: string;
  sku: string;
  shapeCut: string;
  measurements: string;
  weight: string;
  colourGrade: string;
  clarityGrade: string;
  polishSymmetry: string;
  cutGrade: string;
  treatment: string;
  comments: string;
}

export interface CertificateDetailsResponse {
  totalCount: number;
  certificates: CertificateDetails[];
}

export interface CertificateDetails {
  id: number;
  sku: number;
  certificateNumber: string;
  clarity: string;
  colour: string;
  comments: string;
  cutGrade: string;
  measurements: string;
  polish: string;
  shape: string;
  treatment: string;
  weight: number;
  created: Date;
  createdBy: string;
  modified: Date | undefined;
  modifiedBy: string | undefined;
}

export interface CertificateSkuPair {
  certificateNumber: string;
  sku: number;
}
export interface CertificateQuery {
  certificateNumber: string;
  sku: string;
}

export enum GradingCompany {
  GSI,
  ADGL,
  IGI,
}

const Api = {
  createCertificate: (req: CreateCertificate): Promise<void> =>
    msalApiFetch(`/card-certificates`, {
      method: "POST",
      body: JSON.stringify(req),
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
    }),
  getCertificate: (certificateNumber: string): Promise<Certificate> =>
    msalApiFetch(`/grading-company-certificates/${certificateNumber}`, {
      method: "GET",
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      const content: Certificate = await response.json();
      return content;
    }),
  getCertificateList: (
    page: number,
    pageSize: number,
    certificateNumberFilter?: string
  ): Promise<CertificateDetailsResponse> =>
    msalApiFetch(
      `/grading-company-certificates?page=${page}&pageSize=${pageSize}${
        certificateNumberFilter
          ? `&certificateNumberFilter=${certificateNumberFilter}`
          : ""
      }`,
      {
        method: "GET",
      }
    ).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      const content: CertificateDetailsResponse = await response.json();
      return content;
    }),
  getCertificates: (certificates: string[]): Promise<Certificate[]> =>
    msalApiFetch(`/card-certificate-validation`, {
      method: "POST",
      body: JSON.stringify(certificates),
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      const content: Certificate[] = await response.json();
      return content;
    }),
  bulkPrintCertificates: (certificateNumbers: string[]): Promise<Blob> =>
    msalApiFetch(`/pdf`, {
      method: "POST",
      body: JSON.stringify(certificateNumbers),
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      return await response.blob();
    }),
  updateSku: (certificateNumber: string, sku: number): Promise<void> =>
    msalApiFetch(`/grading-company-certificates/${certificateNumber}`, {
      body: JSON.stringify({ sku: sku, certificateNumber: certificateNumber }),
      method: "PUT",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
    }),
  bulkUpdateSkus: (
    pairs: CertificateSkuPair[]
  ): Promise<CertificateSkuPair[]> =>
    msalApiFetch(`/grading-company-certificates`, {
      body: JSON.stringify(pairs),
      method: "POST",
      headers: { "Content-Type": "application/json" },
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
      return await response.json();
    }),
  getCertificateFromIgi: (certificateNumber: string): Promise<void> =>
    msalApiFetch(`/igi/${certificateNumber}`, {
      method: "GET",
    }).then(async (response) => {
      if (!response.ok) {
        return response
          .text()
          .then((text) => Promise.reject(JSON.parse(text).message));
      }
    }),
};

export default Api;
