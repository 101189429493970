import {
  Backdrop,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as React from "react";
import { useEffect, useState } from "react";
import Api from "../../api/DiamondCertificateApi";
import { useSnackbar } from "notistack";
import {
  AutoCompleteOption,
  ClarityGradeOptions,
  ColourGradeOptions,
  GradingCompanyOptions,
  None,
} from "./AddCardCertificateOptions";

type ChangeEvent = React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>;

interface AutoCompleteState {
  gradingCompanyInput: string;
  colourGradeInput: string;
  clarityGradeInput: string;
}

interface Values {
  gradingCompanyOption: AutoCompleteOption;
  certificateNumber: string;
  sku: string;
  shapeCut: string;
  measurements: string;
  weight: string;
  colourGradeOption: AutoCompleteOption;
  clarityGradeOption: AutoCompleteOption;
  polishSymmetry: string;
  cutGrade: string;
  treatment: string;
  comments: string;
  isLoading: boolean;
}

const defaultAutoCompleteState = (): AutoCompleteState => {
  return {
    gradingCompanyInput: "",
    colourGradeInput: "",
    clarityGradeInput: "",
  };
};

const defaultValues = (): Values => {
  return {
    gradingCompanyOption: None,
    certificateNumber: "",
    sku: "",
    shapeCut: "",
    measurements: "",
    weight: "",
    colourGradeOption: None,
    clarityGradeOption: None,
    polishSymmetry: "",
    cutGrade: "",
    treatment: "",
    comments: "",
    isLoading: false,
  };
};

interface Errors {
  gradingCompany: string;
  certificateNumber: string;
  sku: string;
  shapeCut: string;
  measurements: string;
  weight: string;
  colourGrade: string;
  clarityGrade: string;
  polishSymmetry: string;
  cutGrade: string;
  treatment: string;
  comments: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        width: 600,
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
      padding: theme.spacing(2),
    },
  })
);

interface Props {
  setTitle: (title: string) => void;
}

export default function AddCardCertificate(props: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState<Values>(defaultValues());
  const [autoCompleteState, setAutoCompleteState] = useState<AutoCompleteState>(
    defaultAutoCompleteState()
  );
  const [errors, setErrors] = useState<Errors>({} as Errors);

  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    props.setTitle("Add Card Certificate");
  });

  const validate = (values: Values) => {
    const certNumberRegEx = /^[a-z0-9]{1,20}$/i;
    const skuRegex = /^[0-9]{6,8}$/i;
    const alpha20Regex = /^[a-z,\s]{1,20}$/i;
    const alphaSlash20Regex = /^[a-z,\s,/]{1,20}$/i;
    const weightRegex = /^[0-9]{1,2}\.[0-9]{1,2}$/i;
    const error = {} as Errors;

    if (!values.gradingCompanyOption || values.gradingCompanyOption === None) {
      error.gradingCompany = "must not be empty";
    }

    if (
      !values.certificateNumber ||
      !certNumberRegEx.test(values.certificateNumber)
    ) {
      error.certificateNumber =
        "must be between 1 and 20 alphanumeric characters";
    }

    if (!values.sku || !skuRegex.test(values.sku)) {
      error.sku = "must be between 6 and 8 numbers";
    }

    if (!values.shapeCut || !alpha20Regex.test(values.shapeCut)) {
      error.shapeCut = "must be between 1 and 20 letters";
    }

    if (!values.measurements) {
      error.measurements = "must not be empty";
    }

    if (!values.weight || !weightRegex.test(values.weight)) {
      error.weight = "must be a number of the format ##.##";
    }

    if (!values.colourGradeOption || values.colourGradeOption === None) {
      error.colourGrade = "must not be empty";
    }

    if (!values.clarityGradeOption || values.clarityGradeOption === None) {
      error.clarityGrade = "must not be empty";
    }

    if (
      !values.polishSymmetry ||
      !alphaSlash20Regex.test(values.polishSymmetry)
    ) {
      error.polishSymmetry = "must be between 1 and 20 letters";
    }

    if (!values.cutGrade || !alphaSlash20Regex.test(values.cutGrade)) {
      error.cutGrade = "must be between 1 and 20 letters";
    }

    if (!values.treatment || !alpha20Regex.test(values.treatment)) {
      error.treatment = "must be between 1 and 20 letters";
    }

    if (values.comments && values.comments.length > 150) {
      error.comments = "must be 150 characters or less";
    }

    setErrors(error);
    setFormIsValid(
      Object.entries(error).every(([_, err]) => !err || err === "")
    );
  };

  const handleSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if (formIsValid) {
      setState({
        ...state,
        isLoading: true,
      });
      const req = {
        gradingCompany: state.gradingCompanyOption.value,
        certificateNumber: state.certificateNumber,
        sku: state.sku,
        shapeCut: state.shapeCut,
        measurements: state.measurements,
        weight: state.weight,
        colourGrade: state.colourGradeOption.value,
        clarityGrade: state.clarityGradeOption.value,
        polishSymmetry: state.polishSymmetry,
        cutGrade: state.cutGrade,
        treatment: state.treatment,
        comments: state.comments,
      };

      Api.createCertificate(req)
        .then(() => {
          enqueueSnackbar("certificate created", { variant: "success" });
          setState(defaultValues());
          setFormIsValid(false);
        })
        .catch((ex) => {
          setState({
            ...state,
            isLoading: false,
          });
          setAutoCompleteState(defaultAutoCompleteState());
          enqueueSnackbar(ex.toString(), { variant: "error" });
        });
    }
  };

  const onChange = (e: ChangeEvent) => {
    const next = { ...state, [e.target.name]: e.target.value };
    setState(next);
    validate(next);
  };

  const onAutoCompleteChange = (name: string, opt: AutoCompleteOption) => {
    const next = { ...state, [name]: opt };
    setState(next);
    validate(next);
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={state.isLoading}>
        <CircularProgress />
      </Backdrop>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Grid container direction={"column"} spacing={2}>
          <Grid item xs={12} sm={6}>
            <Grid
              container
              direction={"row"}
              spacing={2}
              alignItems={"center"}
              justify={"flex-start"}
            >
              <Grid item xs={10}>
                <Autocomplete
                  id="gradingCompany"
                  options={[None, ...GradingCompanyOptions]}
                  getOptionLabel={(option) => option.title}
                  onChange={(_, newValue: AutoCompleteOption | null) => {
                    onAutoCompleteChange(
                      "gradingCompanyOption",
                      newValue || None
                    );
                  }}
                  value={state.gradingCompanyOption}
                  inputValue={autoCompleteState.gradingCompanyInput}
                  onInputChange={(_, gradingCompanyInput) => {
                    setAutoCompleteState({
                      ...autoCompleteState,
                      gradingCompanyInput,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Grading Company"
                      name="gradingCompany"
                      variant="filled"
                      error={!!errors.gradingCompany}
                      helperText={errors.gradingCompany}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="certificateNumber"
              label="Certificate Number"
              name="certificateNumber"
              variant="filled"
              onChange={onChange}
              value={state.certificateNumber}
              error={!!errors.certificateNumber}
              helperText={errors.certificateNumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="sku"
              label="SKU Number"
              name="sku"
              variant="filled"
              onChange={onChange}
              value={state.sku}
              error={!!errors.sku}
              helperText={errors.sku}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="shapeCut"
              label="Shape/ Cut"
              name="shapeCut"
              variant="filled"
              onChange={onChange}
              value={state.shapeCut}
              error={!!errors.shapeCut}
              helperText={errors.shapeCut}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="measurements"
              label="Measurements"
              name="measurements"
              variant="filled"
              onChange={onChange}
              value={state.measurements}
              error={!!errors.measurements}
              helperText={errors.measurements}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="weight"
              label="Weight"
              name="weight"
              variant="filled"
              onChange={onChange}
              value={state.weight}
              error={!!errors.weight}
              helperText={errors.weight}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="colourGrade"
              options={[None, ...ColourGradeOptions]}
              getOptionLabel={(option) => option.title}
              onChange={(_, newValue: AutoCompleteOption | null) => {
                onAutoCompleteChange("colourGradeOption", newValue || None);
              }}
              value={state.colourGradeOption}
              inputValue={autoCompleteState.colourGradeInput}
              onInputChange={(_, colourGradeInput) => {
                setAutoCompleteState({
                  ...autoCompleteState,
                  colourGradeInput,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Colour Grade"
                  variant="filled"
                  name="colourGrade"
                  error={!!errors.colourGrade}
                  helperText={errors.colourGrade}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="clarityGrade"
              options={[None, ...ClarityGradeOptions]}
              getOptionLabel={(option) => option.title}
              onChange={(_, newValue: AutoCompleteOption | null) => {
                onAutoCompleteChange("clarityGradeOption", newValue || None);
                console.log("test");
              }}
              value={state.clarityGradeOption}
              inputValue={autoCompleteState.clarityGradeInput}
              onInputChange={(_, clarityGradeInput) => {
                setAutoCompleteState({
                  ...autoCompleteState,
                  clarityGradeInput,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Clarity Grade"
                  variant="filled"
                  name="clarityGrade"
                  error={!!errors.clarityGrade}
                  helperText={errors.clarityGrade}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Polish Symmetry"
              name="polishSymmetry"
              variant="filled"
              onChange={onChange}
              value={state.polishSymmetry}
              error={!!errors.polishSymmetry}
              helperText={errors.polishSymmetry}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Cut Grade"
              name="cutGrade"
              variant="filled"
              onChange={onChange}
              value={state.cutGrade}
              error={!!errors.cutGrade}
              helperText={errors.cutGrade}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Treatment"
              name="treatment"
              variant="filled"
              onChange={onChange}
              value={state.treatment}
              error={!!errors.treatment}
              helperText={errors.treatment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Comments"
              name="comments"
              variant="filled"
              multiline
              rowsMax={4}
              onChange={onChange}
              value={state.comments}
              error={!!errors.comments}
              helperText={errors.comments}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={!formIsValid}
              type="submit"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
