import { createContext, useContext } from "react";

export enum Warehouse {
  None = "None",
  AU = "AU",
  CA = "CA",
}

export type WarehouseContextType = {
  warehouse: Warehouse;
  setWarehouse: (warehouse: Warehouse) => void;
};

export const WarehouseContext = createContext<WarehouseContextType>({
  warehouse: Warehouse.AU,
  setWarehouse: (warehouse) => console.warn("warehouse provider not set"),
});
export const useWarehouse = (): WarehouseContextType =>
  useContext(WarehouseContext);
