import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import { Backdrop, CircularProgress, TextField } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import {
  CardCertificateRequest,
  CardCertificateRequestApi,
} from "../../api/CardCertificateRequestApi";
import { printDocket } from "./Docket";
import { useWarehouse } from "../../context/WarehouseContext";
import { useSnackbar } from "notistack";
import {
  getComparator,
  stableSort,
  Order,
} from "../EnhancedTable/EnhancedTable";
import Api from "../../api/DiamondCertificateApi";
import TableData from "./TableData";
import EnhancedTableHead from "./EnhancedTableHead";
import { EnhancedTableToolbar } from "./EnhancedTableToolbar";

function createTableData(req: CardCertificateRequest): TableData {
  return {
    requestID: req.requestID,
    certificateNumber: req.certificateNumber,
    storeSku: req.storeSku,
    store: req.store,
    createdDate: req.dateRequested,
    status: req.status,
    systemSku: req.systemSku?.toString() || "",
    editSku: false,
    validSku: true,
    originalSystemSku: req.systemSku?.toString() || "",
    skuError: "",
    certificateVendor: req.certificateVendor,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    skuContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "250px",
    },
    sku: {
      minWidth: "180px",
      marginRight: "5px",
    },
    skuButtons: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100px",
    },
  })
);

interface CardCertificateRequestsState {
  order: Order;
  orderBy: keyof TableData;
  page: number;
  rowsPerPage: number;
  selected: number[];
  showOpenOrClosed: "open" | "closed";
}

interface Props {
  setTitle: (title: string) => void;
}

export default function CardCertificateRequests(props: Props): JSX.Element {
  const [state, setState] = useState<CardCertificateRequestsState>({
    order: "asc",
    orderBy: "createdDate",
    page: 0,
    rowsPerPage: 10,
    selected: [],
    showOpenOrClosed: "open",
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState<TableData[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.setTitle("Card Certificate Requests");
  });

  const { warehouse } = useWarehouse();

  const loadRequests = () => {
    setIsLoading(true);

    CardCertificateRequestApi.get(warehouse, state.showOpenOrClosed)
      .then((res) => {
        setRows(res.map((r) => createTableData(r)));
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  useEffect(loadRequests, [warehouse, state.showOpenOrClosed, enqueueSnackbar]);

  const classes = useStyles();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof TableData
  ) => {
    const isAsc = state.orderBy === property && state.order === "asc";
    setState({
      ...state,
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selected: number[] = [];
    if (event.target.checked) {
      selected = rows.map((n) => n.requestID);
    }
    setState({
      ...state,
      selected: selected,
    });
  };

  const handleClick = (event: React.MouseEvent<unknown>, requestID: number) => {
    const selectedIndex = state.selected.indexOf(requestID);
    let newSelected: number[] = [];

    if (
      rows.find(
        (r) => r.requestID === requestID && r.status !== "Order Received"
      )
    ) {
      return;
    }

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(state.selected, requestID);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(state.selected.slice(1));
    } else if (selectedIndex === state.selected.length - 1) {
      newSelected = newSelected.concat(state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        state.selected.slice(0, selectedIndex),
        state.selected.slice(selectedIndex + 1)
      );
    }

    setState({
      ...state,
      selected: newSelected,
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setState({
      ...state,
      page: newPage,
    });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setState({
      ...state,
      page: 0,
      rowsPerPage: parseInt(event.target.value, 10),
    });
  };

  const isSelected = (requestID: number) =>
    state.selected.indexOf(requestID) !== -1;

  const emptyRows =
    state.rowsPerPage -
    Math.min(state.rowsPerPage, rows.length - state.page * state.rowsPerPage);

  const selectedCertificates = rows.filter((r) => isSelected(r.requestID));

  const toggleOpenClosed = () => {
    setState({
      ...state,
      showOpenOrClosed: state.showOpenOrClosed === "open" ? "closed" : "open",
    });
  };

  const printSelection = () => {
    setIsLoading(true);
    CardCertificateRequestApi.printCertificates(
      selectedCertificates.map((c) => c.certificateNumber)
    )
      .then((blob) => {
        const TableDataUrl = window.URL.createObjectURL(blob);
        const pdfWindow = window.open(TableDataUrl);
        pdfWindow?.print();
        setTimeout(() => {
          printDocket(selectedCertificates);
        }, 2000);
      })
      .then(loadRequests)
      .catch((e) => {
        setIsLoading(false);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const cancelCertificateRequest = (requestId: number) => {
    setIsLoading(true);
    CardCertificateRequestApi.cancel(requestId).then(loadRequests);
  };

  const toggleEditSku = (
    requestId: number,
    event?: React.MouseEvent<HTMLSpanElement | SVGSVGElement, MouseEvent>
  ) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (state.showOpenOrClosed === "closed") {
      return;
    }

    const rs = [...rows];
    const index = rs.findIndex((r) => r.requestID === requestId);
    if (index === -1) return;
    rs[index].editSku = !rs[index].editSku;
    rs[index].systemSku = rs[index].originalSystemSku;
    rs[index].skuError = "";
    setRows(rs);
  };

  const saveSkus = (
    requestId: number,
    certificateNumber: string,
    sku: string
  ) => {
    setIsLoading(true);
    return Api.updateSku(certificateNumber, parseInt(sku))
      .then(() => {
        const rs = [...rows];
        const index = rs.findIndex((r) => r.requestID === requestId);
        if (index === -1) return;
        rs[index].editSku = !rs[index].editSku;
        rs[index].originalSystemSku = sku;
        setRows(rs);
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        const rs = [...rows];
        const index = rs.findIndex((r) => r.requestID === requestId);
        if (index === -1) return;
        rs[index].skuError = e.toString();
        setRows(rs);
        enqueueSnackbar(e.toString(), { variant: "error" });
      });
  };

  const modifySku = (value: string, index: number) => {
    const rs = [...rows];
    rs[index].systemSku = value;
    rs[index].skuError = "";
    setRows(rs);
  };

  return (
    <div className={classes.root}>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress />
      </Backdrop>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          numSelected={state.selected.length}
          printSelection={printSelection}
          selectedCertificates={selectedCertificates}
          toggleOpenClosed={toggleOpenClosed}
          openClosedStatus={state.showOpenOrClosed}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size="medium"
            aria-label="requests table"
          >
            <EnhancedTableHead
              numSelected={state.selected.length}
              order={state.order}
              orderBy={state.orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              disableSelectAll={state.showOpenOrClosed === "closed"}
            />
            <TableBody>
              {stableSort(rows, getComparator(state.order, state.orderBy))
                .slice(
                  state.page * state.rowsPerPage,
                  state.page * state.rowsPerPage + state.rowsPerPage
                )
                .map((row, index) => {
                  const isItemSelected = isSelected(row.requestID);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.requestID}
                      selected={isItemSelected}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(event) => handleClick(event, row.requestID)}
                      >
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          disabled={row.status !== "Order Received"}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        onClick={(event) => handleClick(event, row.requestID)}
                      >
                        {row.certificateNumber}
                      </TableCell>
                      <TableCell>
                        {row.editSku ? (
                          <div className={classes.skuContainer}>
                            <TextField
                              className={classes.sku}
                              value={row.systemSku}
                              onChange={(e) => modifySku(e.target.value, index)}
                              type={"number"}
                              error={!!row.skuError}
                              helperText={row.skuError}
                            ></TextField>
                            <span className={classes.skuButtons}>
                              <Tooltip title="Save edit">
                                <SaveIcon
                                  onClick={(e) =>
                                    saveSkus(
                                      row.requestID,
                                      row.certificateNumber,
                                      row.systemSku
                                    )
                                  }
                                ></SaveIcon>
                              </Tooltip>
                              <Tooltip title="Cancel edit">
                                <CancelIcon
                                  onClick={(e) =>
                                    toggleEditSku(row.requestID, e)
                                  }
                                ></CancelIcon>
                              </Tooltip>
                            </span>
                          </div>
                        ) : (
                          <span
                            className={classes.skuContainer}
                            onClick={(e) => toggleEditSku(row.requestID, e)}
                          >
                            <span className={classes.sku}>{row.systemSku}</span>
                            <Tooltip
                              title="Edit SKU"
                              className={
                                state.showOpenOrClosed === "closed"
                                  ? classes.visuallyHidden
                                  : undefined
                              }
                            >
                              <span className={classes.skuButtons}>
                                <EditIcon></EditIcon>
                              </span>
                            </Tooltip>
                          </span>
                        )}
                      </TableCell>
                      <TableCell align="right">{row.storeSku}</TableCell>
                      <TableCell align="left">{row.store}</TableCell>
                      <TableCell align="left">
                        {row.certificateVendor}
                      </TableCell>
                      <TableCell align="left">{row.status}</TableCell>
                      <TableCell align="left">
                        {new Date(row.createdDate).toLocaleString("en-AU")}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Cancel request">
                          <span>
                            <IconButton
                              aria-label="cancel"
                              name="cancel"
                              onClick={() =>
                                cancelCertificateRequest(row.requestID)
                              }
                              disabled={row.status !== "Order Received"}
                            >
                              <CancelIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={rows.length}
          rowsPerPage={state.rowsPerPage}
          page={state.page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}
